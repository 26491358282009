<template>
  <!-- 系统文章页面 -->
  <Layout>
    <div class="main-agreement">
      <div class="base-width">
        <div class="md-agree c-card">
          <div class="title s26 font-medium text-center">采购协议</div>
          <div class="article editor s16 color-4">
            <p>近日获悉，新中轴线南段的广州文化基础设施“四大馆”之一——广州科学馆计划2023年11月30日开工，2027年12月18日完成主体工程竣工验收。项目采用“悬浮城坊”设计方案，灵感源自宋朝哥窑青瓷、科技魔方和传统岭南街区冷巷，建成后将成为现代公共文化设施、科技传播交流平台、公民终身学习伙伴、科学文化旅游地标。</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {

  },
  created() {
    this.getData()
  },
  mounted() {
  },
  methods: {
    getData() {
      // 文章id => this.$route.query.id
    }
  }
};
</script>

<style lang="scss" scoped>
.main-agreement{
  padding: .3rem 0;
}
.md-agree{
  padding: .3rem;
  width: 12.32rem;
  margin: auto;
  min-height: 60vh;
  .title{
    border-bottom: 1px solid #e6f2f8;
    padding-bottom: .1rem;
    margin-bottom: .2rem;
  }
}
</style>